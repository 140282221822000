<div class="page-content">
  <!----------------------- Header ------------------------->
  <div
    [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'"
    class="page-panel"
  >
    <!-- Bouton flex office -->
    <div
      [ngClass]="!(isHandset$ | async) ? '' : 'header-button-mobile'"
      class="header-button fs-14 fw-500 secondary-color"
      (click)="openUrl('https://moffi.typeform.com/to/ftcWG0rc')"
    >
      <img src="assets/images/icon/icon_rating.svg" alt="icon_rating" />
      <span style="max-width: 90%">{{ "home.testFlex" | translate }}</span>
      <mat-icon class="header-button-icon fs-20" fontIcon="east"></mat-icon>
    </div>
    <!-- Image -->
    <div class="header-images">
      <div style="position: relative">
        <!-- Image Business Meeting-->
        <div
          class="img-absolute headerImage header-image header-image-business_meeting"
        >
          <img
            src="assets/images/landings/home/header/{{
              currentLanguage
            }}/float_business_meeting.webp"
            alt=""
            srcset="
              assets/images/landings/home/header/{{
                currentLanguage
              }}/float_business_meeting.webp 1x,
              assets/images/landings/home/header/{{
                currentLanguage
              }}/float_business_meeting-2x.webp 2x
            "
          />
        </div>
        <!-- Image Desk 08-->
        <div class="img-absolute headerImage header-image header-image-desk-08">
          <img
            src="assets/images/landings/home/header/{{
              currentLanguage
            }}/float_poste_08.webp"
            alt=""
            srcset="
              assets/images/landings/home/header/{{
                currentLanguage
              }}/float_poste_08.webp 1x,
              assets/images/landings/home/header/{{
                currentLanguage
              }}/float_poste_08-2x.webp 2x
            "
          />
        </div>
        <!-- Image Home Office-->
        <div class="img-absolute headerImage header-image header-image-remote">
          <img
            src="assets/images/landings/home/header/{{
              currentLanguage
            }}/float_home_office.webp"
            alt=""
            srcset="
              assets/images/landings/home/header/{{
                currentLanguage
              }}/float_home_office.webp 1x,
              assets/images/landings/home/header/{{
                currentLanguage
              }}/float_home_office-2x.webp 2x
            "
          />
        </div>
        <!-- Image Absence -->
        <div class="img-absolute headerImage header-image header-image-absence">
          <img
            src="assets/images/landings/home/header/{{
              currentLanguage
            }}/float_absence.webp"
            alt=""
            srcset="
              assets/images/landings/home/header/{{
                currentLanguage
              }}/float_absence.webp 1x,
              assets/images/landings/home/header/{{
                currentLanguage
              }}/float_absence-2x.webp 2x
            "
          />
        </div>

        <!-- Desk 08 -->
        <div
          class="img-absolute headerImagePic header-image header-image-desk08"
        >
          <img
            src="assets/images/landings/home/header/{{
              currentLanguage
            }}/desk08_large.webp"
            alt=""
            srcset="
              assets/images/landings/home/header/{{
                currentLanguage
              }}/desk08_large.webp 1x,
              assets/images/landings/home/header/{{
                currentLanguage
              }}/desk08_large-2x.webp 2x
            "
          />
        </div>

        <!-- Desk 11 -->
        <div
          class="img-absolute headerImagePic header-image header-image-desk11"
        >
          <img
            src="assets/images/landings/home/header/{{
              currentLanguage
            }}/desk11_large.webp"
            alt=""
            srcset="
              assets/images/landings/home/header/{{
                currentLanguage
              }}/desk11_large.webp 1x,
              assets/images/landings/home/header/{{
                currentLanguage
              }}/desk11_large-2x.webp 2x
            "
          />
        </div>

        <img
          style="max-height: 519px"
          fetchpriority="high"
          src="assets/images/landings/home/header/{{
            currentLanguage
          }}/header.webp"
          srcset="
            assets/images/landings/home/header/{{
              currentLanguage
            }}/header.webp 1x,
            assets/images/landings/home/header/{{
              currentLanguage
            }}/header-2x.webp 2x
          "
          alt="{{ 'home.hero_image.main.alt' | translate }}"
        />
      </div>
    </div>

    <!-- Titre -->
    <div class="page-panel" style="margin-top: 0rem">
      <div class="page-panel-title">
        <h1
          appColoredText
          [textColor]="'#074DCE'"
          textKey="home.caption.title"
        ></h1>
        <h4>{{ "home.caption.description" | translate }}</h4>
      </div>
      <!-- Bouton réserver une démo -->
      <div style="margin-top: 1rem">
        <moffi-ui-button
          (eventClick)="redirectTo('contact')"
          buttonType="light"
          label="{{ 'home.caption.button' | translate }}"
        ></moffi-ui-button>
      </div>
    </div>
  </div>

  <!----------------------- Clients ------------------------->
  <app-list-client
    class="client-panel"
    title="{{ 'home.clients.description' | translate }}"
    [clients]="clients"
  ></app-list-client>

  <!----------------------- Flexibilité ------------------------->
  <div class="page-panel home-panel-large">
    <!-- title -->
    <div class="page-panel-title">
      <div>
        <img
          src="assets/images/landings/page/hands/hand_flex_{{
            colorUsecases
          }}.svg"
          alt="icon_hand_flex"
        />
        <h2
          appColoredText
          [textColor]="colorUsecasesHex"
          textKey="home.usecases.title"
        ></h2>
      </div>
      <h4>
        {{ "home.usecases.description" | translate }}
      </h4>
    </div>
    <div style="width: 100%">
      <app-usecases-panel
        (colorChanged)="onColorUseCasesChanged($event)"
      ></app-usecases-panel>
    </div>
  </div>

  <!----------------------- Stores ------------------------->
  <div class="page-panel">
    <!-- title -->
    <div class="page-panel-title">
      <div>
        <img
          src="assets/images/landings/page/hands/hand_stores.svg"
          alt="icon_hand_stores"
        />
        <h2
          appColoredText
          [textColor]="'#074DCE'"
          textKey="home.mobile.title"
        ></h2>
      </div>
      <h4>
        {{ "home.mobile.description" | translate }}
      </h4>
    </div>
    <!-- Appstore + Google Play-->
    <div class="page-horizontal-panel">
      <div
        (click)="
          openUrl(
            'https://apps.apple.com/fr/app/moffi/id1528941537?platform=iphone'
          )
        "
        class="1"
      >
        <img
          class="store-app"
          src="assets/images/footer/{{ currentLanguage }}/app_store.svg"
          alt="Apple store"
        />
      </div>
      <div
        (click)="
          openUrl(
            'https://play.google.com/store/apps/details?id=io.moffi.moffi_next&pli=1'
          )
        "
        class="1"
      >
        <img
          class="store-app"
          src="assets/images/footer/{{ currentLanguage }}/google_play.svg"
          alt="Google Play"
        />
      </div>
    </div>
    <!-- Illustration -->
    <div class="store-images">
      <div class="2 store-image">
        <img
          src="assets/images/landings/home/stores/{{
            currentLanguage
          }}/notification.webp"
          alt="{{ 'home.mobile.image_notification.alt' | translate }}"
        />
      </div>
      <img
        src="assets/images/landings/home/stores/{{ currentLanguage }}/phone.webp"
        alt="{{ 'home.mobile.image.alt' | translate }}"
      />
    </div>
  </div>

  <div class="home-background-bottom home-panel-large page-content">
    <!----------------------- Connexion aux outils ------------------------->
    <div class="page-panel home-panel-large">
      <!-- title -->
      <div class="page-panel-title">
        <div>
          <img
            src="assets/images/landings/page/hands/hand_tools.svg"
            alt="icon_hand_tools"
          />
          <h2
            appColoredText
            [textColor]="'#074DCE'"
            textKey="home.tools.title"
          ></h2>
        </div>

        <h4>
          {{ "home.tools.description" | translate }}
        </h4>
      </div>
      <div [ngClass]="(isHandset$ | async) ? 'home-panel-large' : ''">
        <!-- Tools panel component -->
        <app-tools-panel>
          <!-- SIRH & Active Directory-->
          <div>
            <app-tool
              toolImgSrc="assets/images/icon/icon_cloud.svg"
              toolImgAlt="icon_cloud"
              toolTitle="{{ 'home.tools.humanResources' | translate }}"
              [toolIcons]="[
                'assets/images/logo/lucca.svg',
                'assets/images/logo/adp.svg',
                'assets/images/logo/bamboo.svg'
              ]"
              [toolAlts]="['Lucca', 'ADP', 'Bamboo']"
            ></app-tool>
          </div>
          <!-- Connexion-->
          <div>
            <app-tool
              toolImgSrc="assets/images/icon/icon_key.svg"
              toolImgAlt="icon_key"
              toolTitle="{{ 'home.tools.login' | translate }}"
              [toolIcons]="[
                'assets/images/logo/google.svg',
                'assets/images/logo/microsoft.svg',
                'assets/images/logo/okta.svg'
              ]"
              [toolAlts]="['Google', 'Microsoft', 'Okta']"
            ></app-tool>
          </div>
          <!-- Agenda -->
          <div>
            <app-tool
              toolImgSrc="assets/images/icon/icon_calendar.svg"
              toolImgAlt="icon_calendar"
              toolTitle="{{ 'home.tools.calendar' | translate }}"
              [toolIcons]="[
                'assets/images/logo/google_calendar.svg',
                'assets/images/logo/outlook.svg'
              ]"
              [toolAlts]="['Google Calendar', 'Outlook']"
            ></app-tool>
          </div>
          <!-- SIRH & Active Directory-->
          <div>
            <app-tool
              toolImgSrc="assets/images/icon/icon_tools.svg"
              toolImgAlt="icon_tools"
              toolTitle="{{ 'home.tools.cooperation' | translate }}"
              [toolIcons]="[
                'assets/images/logo/slack.svg',
                'assets/images/logo/teams.svg'
              ]"
              [toolAlts]="['Slack', 'Teams']"
            ></app-tool>
          </div>
        </app-tools-panel>
      </div>
      <div class="home-card-font primary-color home-tools-text">
        {{ "home.tools.more" | translate }}
        <mat-icon class="tools-arrow-icon" fontIcon="shortcut"></mat-icon>
      </div>
      <div>
        <moffi-ui-button
          (eventClick)="redirectTo('marketplace')"
          buttonType="light"
          label="{{ 'home.tools.button.marketplace' | translate }}"
        ></moffi-ui-button>
      </div>
    </div>

    <!----------------------- Performance et sécurité ------------------------->
    <div class="page-panel">
      <!-- title -->
      <div class="page-panel-title">
        <img
          src="assets/images/landings/page/hands/hand_security.svg"
          alt="icon_hand_security"
        />
        <h2
          appColoredText
          [textColor]="'#074DCE'"
          textKey="home.security.title"
        ></h2>
      </div>
      <!-- Panel cards -->
      <div
        [ngClass]="
          !(isHandset$ | async) ? 'page-panel-cards' : 'page-panel-cards-mobile'
        "
      >
        <div class="page-panel-card">
          <!-- Green -->
          <app-panel-card-small
            matIconName="zoom_out_map"
            text="{{ 'home.security.scale' | translate }}"
            imgSrc="assets/images/illustrations/scale.webp"
            imgAlt="Scale"
          ></app-panel-card-small>
        </div>
        <div class="page-panel-card">
          <!-- Pink -->
          <app-panel-card-small
            matIconName="lock"
            text="{{ 'home.security.owasp' | translate }}"
            imgSrc="assets/images/illustrations/owasp.webp"
            imgAlt="Owasp"
            borderColor="#FF94F4"
            linearGradientColor1="#FFECFD"
            linearGradientColor2="rgba(255, 236, 253, 0.25)"
            boxShadowColor="rgba(255, 148, 244, 0.20)"
          ></app-panel-card-small>
        </div>
        <div class="page-panel-card">
          <!-- Orange -->
          <app-panel-card-small
            matIconName="assignment_turned_in"
            text="{{ 'home.security.iso' | translate }}"
            imgSrc="assets/images/illustrations/iso.webp"
            imgAlt="ISO"
            borderColor="#FFBC6E"
            linearGradientColor1="#FDF3E9"
            linearGradientColor2="rgba(253, 243, 233, 0.25)"
            boxShadowColor="rgba(255, 188, 110, 0.20)"
          ></app-panel-card-small>
        </div>
      </div>
    </div>

    <!----------------------- Accompagnement ------------------------->
    <div class="page-panel">
      <!-- title -->
      <div class="page-panel-title">
        <div>
          <img
            src="assets/images/landings/page/hands/hand_support.svg"
            alt="icon_hand_support"
          />
          <h2
            appColoredText
            [textColor]="'#074DCE'"
            textKey="home.support.title"
          ></h2>
        </div>
        <h4>
          {{ "home.support.description" | translate }}
        </h4>
        <span class="fs-16 fw-400 third-color">{{
          "home.support.premium" | translate
        }}</span>
      </div>

      <div
        [ngClass]="!(isHandset$ | async) ? 'home-steps' : 'home-steps-mobile'"
      >
        <app-panel-step>
          <!-- Step 1 : Mise en place -->
          <app-step
            iconSrc="assets/images/landings/page/steps/start.svg"
            iconAlt="icon_start"
            stepIndexColor="#E78C23"
            title="{{ 'home.support.start.title' | translate }}"
            description="{{ 'home.support.start.description' | translate }}"
          ></app-step>
          <!-- Step 2 : Configuration sur mesure -->
          <app-step
            iconSrc="assets/images/landings/page/steps/setup.svg"
            iconAlt="icon_setup"
            stepIndexColor="#CB62C0"
            title="{{ 'home.support.setup.title' | translate }}"
            description="{{ 'home.support.setup.description' | translate }}"
          ></app-step>
          <!-- Step 3 : Phase de test -->
          <app-step
            iconSrc="assets/images/landings/page/steps/test.svg"
            iconAlt="icon_test"
            stepIndexColor="#B494FF"
            title="{{ 'home.support.test.title' | translate }}"
            description="{{ 'home.support.test.description' | translate }}"
          ></app-step>
          <!-- Step 4 : Formation personnalisée -->
          <app-step
            iconSrc="assets/images/landings/page/steps/training.svg"
            iconAlt="icon_training"
            stepIndexColor="#074DCE"
            title="{{ 'home.support.training.title' | translate }}"
            description="{{ 'home.support.training.description' | translate }}"
          ></app-step>
          <!-- Step 5 : Conseils & bonnes pratiques -->
          <app-step
            iconSrc="assets/images/landings/page/steps/tips.svg"
            iconAlt="icon_tips"
            stepIndexColor="#45C6AA"
            title="{{ 'home.support.tips.title' | translate }}"
            description="{{ 'home.support.tips.description' | translate }}"
          ></app-step>
          <!-- Step 6 : Lancement du projet -->
          <app-step
            iconSrc="assets/images/landings/page/steps/launch.svg"
            iconAlt="icon_launch"
            stepIndexColor="#06BCE4"
            title="{{ 'home.support.launch.title' | translate }}"
            description="{{ 'home.support.launch.description' | translate }}"
          ></app-step>
        </app-panel-step>
      </div>
    </div>

    <!----------------------- Ambasadeurs ------------------------->
    <div
      class="page-panel"
      [style]="
        !(isHandset$ | async) ? 'margin-bottom: 8rem;' : 'margin-bottom: 1rem;'
      "
    >
      <!-- title -->
      <div class="page-panel-title">
        <div>
          <img
            src="assets/images/landings/page/hands/hand_testimony.svg"
            alt="icon_hand_testimony"
          />
          <h2
            appColoredText
            [textColor]="'#074DCE'"
            textKey="home.testimony.title"
          ></h2>
        </div>
        <h4>
          {{ "home.testimony.description" | translate }}
        </h4>
      </div>

      <!-- Panel cards-->
      <div
        [ngClass]="
          !(isHandset$ | async) ? 'page-panel-cards' : 'page-panel-cards-mobile'
        "
      >
        <div class="page-panel-card">
          <!-- Citeo -->
          <app-panel-card-large
            iconSrc="assets/images/icon/icon_quote/quote_cyan.svg"
            iconAlt="quote_cyan"
            text="{{ 'home.testimony.first.text' | translate }}"
            imgSrc="assets/images/illustrations/testimonies/show_room.webp"
            imgAlt="Citeo"
            pTitle="{{ 'home.testimony.first.name' | translate }}"
            pText="{{ 'home.testimony.first.position' | translate }}"
            borderColor="#94ECFF"
            linearGradientColor1="#E4FAFF"
            linearGradientColor2="rgba(228, 250, 255, 0.25)"
            boxShadowColor="rgba(69, 198, 170, 0.20)"
            linkColor="#00ACD1"
          ></app-panel-card-large>
        </div>
        <div class="page-panel-card">
          <!-- Effy -->
          <app-panel-card-large
            iconSrc="assets/images/icon/icon_quote/quote_purple.svg"
            iconAlt="quote_purple"
            text="{{ 'home.testimony.second.text' | translate }}"
            imgSrc="assets/images/illustrations/testimonies/santevet.webp"
            imgAlt="Effy"
            pTitle="{{ 'home.testimony.second.name' | translate }}"
            pText="{{ 'home.testimony.second.position' | translate }}"
            borderColor="#B494FF"
            linearGradientColor1="#EFEBFC"
            linearGradientColor2="rgba(239, 235, 252, 0.25)"
            boxShadowColor="rgba(180, 148, 255, 0.20)"
            linkColor="#7E47FF"
          ></app-panel-card-large>
        </div>
        <div class="page-panel-card">
          <!-- Le Village -->
          <app-panel-card-large
            iconSrc="assets/images/icon/icon_quote/quote_pink.svg"
            iconAlt="quote_pink"
            text="{{ 'home.testimony.third.text' | translate }}"
            imgSrc="assets/images/illustrations/testimonies/cyrillus.webp"
            imgAlt="Le Village"
            pTitle="{{ 'home.testimony.third.name' | translate }}"
            pText="{{ 'home.testimony.third.position' | translate }}"
            borderColor="#FF94F4"
            linearGradientColor1="#FFECFD"
            linearGradientColor2="rgb(255, 236, 253, 0.25)"
            boxShadowColor="rgba(255, 148, 244, 0.20)"
            linkColor="#CB62C0"
          ></app-panel-card-large>
        </div>
      </div>
    </div>
  </div>

  <!----------------------- Bandeau démo ------------------------->
  <div style="margin-top: 0rem" class="page-demo-panel">
    <!-- Background -->
    <div class="page-demo-background">
      <div
        style="width: 50rem; height: 40rem; top: -61%; left: 76%"
        class="gradient-circle blue-gradient"
      ></div>
      <div
        style="top: -8%; left: 80%; transform: rotate(140deg); opacity: 0.9"
        class="gradient-small-circle pink-gradient"
      ></div>
      <div
        style="
          top: -13%;
          left: 82%;
          transform: rotate(140deg);
          filter: blur(5rem);
        "
        class="gradient-small-circle yellow-gradient"
      ></div>
      <div
        style="width: 50rem; height: 40rem; top: 40%; left: 65%"
        class="gradient-circle blue-gradient"
      ></div>
      <div
        style="top: 39%; left: 81%; opacity: 0.8"
        class="gradient-small-circle green-gradient"
      ></div>
      <div
        style="width: 50rem; height: 50rem; top: 76%; left: 73%; opacity: 0.7"
        class="gradient-circle purple-gradient"
      ></div>
    </div>

    <!-- title -->
    <div class="page-panel-title">
      <img
        src="assets/images/landings/page/hands/hand_demo.svg"
        alt="icon_hand_demo"
      />
      <h2 class="dark-color">{{ "home.cta.title" | translate }}</h2>
      <h4
        class="dark-color"
        appColoredText
        [textColor]="'#7285D8'"
        textKey="home.cta.description"
      ></h4>
    </div>

    <!-- Bouton démo -->
    <moffi-ui-button
      (eventClick)="redirectTo('contact')"
      buttonType="dark"
      label="{{ 'home.cta.button' | translate }}"
    ></moffi-ui-button>
  </div>
</div>
