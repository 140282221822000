<div *ngIf="!(isChildRoute$ | async)" class="page-content">

  <!----------------------- Header ------------------------->
  <div [style.margin-top]="!(isHandset$ | async) ? '5rem' : '3rem'" [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'" class="page-panel">
    <!-- Titre -->
    <div class="page-panel" style="margin-top: 0rem;">
        <div class="page-panel-title">
            <h1 class="fadePanelHeader fade-panel" appColoredText [textColor]="'#074DCE'" textKey="glossary.main.caption.title"></h1>
            <h4 class="fadePanelHeader fade-panel">{{'glossary.main.caption.description' | translate}}</h4>
        </div>
    </div>
</div>

  <!-- Glossaire -->
  <div class="page-panel" style="margin-top: 6rem;">
    <div class="glossary-panel fadePanelHeader fade-panel">
      <div *ngFor="let letter of getSortedKeys()" style="width: 100%; height: 100%;">
        <app-glossary-item
            (onClickEvent)="openDefinitionDetail($event)" 
            [letter]="letter"
            [listDefinition]="groupedDefinitions[letter]" 
            width="100%" 
            height="100%">
        </app-glossary-item>
      </div> 
    </div>
  </div>

   <!----------------------- Bandeau démo ------------------------->
   <div style="margin-top: 10rem" class="page-demo-panel">
    <!-- Background -->
    <div class="page-demo-background">
      <div style="width: 50rem; height: 40rem; top: -61%; left: 76%" class="gradient-circle blue-gradient"></div>
      <div style="top: -8%; left: 80%; transform: rotate(140deg); opacity: 0.9" class="gradient-small-circle pink-gradient"></div>
      <div style="top: -13%;left: 82%;transform: rotate(140deg);filter: blur(5rem);" class="gradient-small-circle yellow-gradient"></div>
      <div style="width: 50rem; height: 40rem; top: 40%; left: 65%" class="gradient-circle blue-gradient"></div>
      <div style="top: 39%; left: 81%; opacity: 0.8" class="gradient-small-circle green-gradient"></div>
      <div style="width: 50rem; height: 50rem; top: 76%; left: 73%; opacity: 0.7" class="gradient-circle purple-gradient"></div>
    </div>

    <!-- title -->
    <div class="page-panel-title">
      <img src="assets/images/landings/page/hands/hand_demo.svg" alt="icon_hand_demo"/>
      <h2 class="dark-color">{{ "glossary.cta.title" | translate }}</h2>
      <h4 class="dark-color" appColoredText [textColor]="'#7285D8'" textKey="glossary.cta.description"></h4>
    </div>

    <!-- Bouton démo -->
    <moffi-ui-button
      (eventClick)="redirectTo('contact')"
      buttonType="dark"
      label="{{ 'glossary.cta.button' | translate }}"
    ></moffi-ui-button>
  </div>
</div>


<!-- Template de la page Definition - Voir component DefinitionComponent -->
<router-outlet></router-outlet>








