import { Component, ViewEncapsulation } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';
import { ModelDefinition } from 'src/app/model/model-definition';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ParamMap } from '@angular/router';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-definition',
  templateUrl: './definition.component.html',
  styleUrl: './definition.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DefinitionComponent extends AbstractPageComponent {


  modelDefinition?: ModelDefinition;
  private subscription!: Subscription;

  override ngOnInit(): void {
    super.ngOnInit();

    this.subscription = this.activatedRoute.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const id = params.get('id');
        if (id) {
          return this.definitionsService.getDefinitionByUrl(id);
        }
        throw new Error('Definition ID is required');
      })
    ).subscribe(definition => {
      this.modelDefinition = definition;
      this.setHeaderMetadatas();
      // Observer les changements dans isChildRoute$
      this.isChildRoute$.subscribe((value: boolean) => {
        if (!value) {
          this.launchAnimations();
        }
      });
    }, error => {
      console.error('Failed to load definition data', error);
    });

  }

  override setHeaderMetadatas(): void {
    this.title.setTitle(this.getTranslation('glossary.' + this.modelDefinition?.key + '.metadata.title'));
    this.meta.updateTag({name: 'description', content: this.getTranslation('glossary.' + this.modelDefinition?.key + '.metadata.description'),});
  
    document
      ?.getElementById('alternate-fr-fr')
      ?.setAttribute('href', environment.frontUrl + '/fr/contact');
  
  }

  override launchAnimations(): void {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll('.fadePanelHeader','fadeIn 0.5s ease-in-out forwards',0.1,0);

    // Animations des panels Part
    let fadePanelPart: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelPart.handleScroll('.fadePanelPart','fadeIn 0.5s ease-in-out forwards',0.1,0);

    // Animations du panel Card
    let fadePanelCard: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelCard.handleScroll('.fadePanelCard','fadeIn 0.5s ease-in-out forwards',0.2,0);
    
    // Animations du panel Articles
    let fadePanelArticles: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelArticles.handleScroll('.fadePanelArticles','fadeIn 0.5s ease-in-out forwards',0.3,0); 
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe(); 
    }
  }

}
