import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { HomeComponent } from './page/landings/home/home.component';
import { RemoteComponent } from './page/landings/remote/remote.component';
import { FlexComponent } from './page/landings/flex/flex.component';
import { SmartComponent } from './page/landings/smart/smart.component';
import { ContactComponent } from './page/landings/contact/contact.component';
import { ContactIdeaComponent } from './page/landings/contact-idea/contact-idea.component';
import { ContactJoinTeamComponent } from './page/landings/contact-join-team/contact-join-team.component';
import { OfferComponent } from './page/landings/offer/offer.component';
import { MarketplaceComponent } from './page/landings/marketplace/marketplace.component';
import { MessageEnvoyeComponent } from './page/landings/message-envoye/message-envoye.component';
import { PartnerComponent } from './page/landings/partner/partner.component';
import { GlossaryComponent } from './page/landings/glossary/glossary.component';
import { DefinitionComponent } from './page/landings/definition/definition.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: ':lang', // Capture du code langue comme paramètre dynamique
    children: [
      { path: '', component: HomeComponent, data: { page: 'home' } },
      {
        path: 'solutions/smart-office',
        component: SmartComponent,
        data: { page: 'smart' },
      },
      {
        matcher: (url) => {
          const path = url.map((segment) => segment.path).join('/');
          if (
            RegExp(/solutions\/(logiciel-flex-office|hybrid-work)/).exec(path)
          ) {
            return { consumed: url };
          }

          return null;
        },
        component: FlexComponent,
        data: { page: 'flex' },
      },
      {
        matcher: (url) => {
          const path = url.map((segment) => segment.path).join('/');
          if (
            RegExp(
              /solutions\/outil-suivi-teletravail|solutions\/outil-suivi-travail-a-domicile|solutions\/remote-work/
            ).exec(path)
          ) {
            return { consumed: url };
          }

          return null;
        },
        component: RemoteComponent,
        data: { page: 'remote' },
      },
      {
        matcher: (url) => {
          if (url.length === 1 && RegExp(/contact|demo/).exec(url[0].path)) {
            return { consumed: url };
          }

          return null;
        },
        component: ContactComponent,
        data: { page: 'contact' },
      },
      {
        matcher: (url) => {
          if (
            url.length === 1 &&
            RegExp(/notre-offre-flex-office|our-offer/).exec(url[0].path)
          ) {
            return { consumed: url };
          }

          return null;
        },
        component: OfferComponent,
        data: { page: 'pricing' },
      },
      {
        matcher: (url) => {
          if (
            url.length === 1 &&
            RegExp(/soumettre-une-idee|submit-an-idea/).exec(url[0].path)
          ) {
            return { consumed: url };
          }

          return null;
        },
        component: ContactIdeaComponent,
        data: { page: 'idea' },
      },
      {
        matcher: (url) => {
          if (
            url.length === 1 &&
            RegExp(/rejoindre-l-equipe|join-the-team/).exec(url[0].path)
          ) {
            return { consumed: url };
          }

          return null;
        },
        component: ContactJoinTeamComponent,
        data: { page: 'join_team' },
      },
      {
        matcher: (url) => {
          const pathMatch = /^(partenaires|marketplace)$/.exec(url[0].path);
          if (pathMatch && url.length >= 1) {
            return {
              consumed: url.slice(0, 1),
              posParams: { pathMatch: new UrlSegment(pathMatch[0], {}) },
            };
          }
          return null;
        },
        component: MarketplaceComponent,
        data: { page: 'marketplace' },
        children: [
          {
            path: ':id',
            component: PartnerComponent,
            data: { page: 'marketplace' },
          },
        ],
      },
      {
        matcher: (url) => {
          const pathMatch = /^(glossaire-flex-office|glossary-hybrid-work)$/.exec(
            url[0].path
          );
          if (pathMatch && url.length >= 1) {
            return {
              consumed: url.slice(0, 1),
              posParams: { pathMatch: new UrlSegment(pathMatch[0], {}) },
            };
          }
          return null;
        },
        component: GlossaryComponent,
        data: { page: 'glossary' },
        children: [
          {
            path: ':id',
            component: DefinitionComponent,
            data: { page: 'glossary' },
          },
        ],
      },
      {
        matcher: (url) => {
          if (
            url.length === 1 &&
            RegExp(/message-envoye|message-sent/).exec(url[0].path)
          ) {
            return { consumed: url };
          }

          return null;
        },
        component: MessageEnvoyeComponent,
        data: { page: 'message_sent' },
      },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: '/fr', pathMatch: 'full' }, // Redirection par défaut
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
