<div class="panel-card-large">
    <!-- Icone + texte -->
    <div class="panel-card-large-content">
        <img src="{{iconSrc}}" alt="{{iconAlt}}"/>
        <div class="fw-500 fs-20 f-style-italic primary-color">{{text}}</div>
    </div>
    <!-- Lien -->
    <a *ngIf="link" [style.color]="linkColor" href="{{link}}">{{'home.testimony.seeMore' | translate}}</a>

    <!-- Image + Sous-text-->
    <div class="panel-card-large-subcontent">
        <div class="panel-card-large-subcontent-img">
            <img src="{{imgSrc}}" alt="{{imgAlt}}"/>
        </div>
        <div class="panel-card-large-subcontent-text fs-16 fw-600 primary-color">{{pTitle}}
            <span class="fs-14 fw-400">{{pText}}</span>
        </div>
    </div>
    
</div>
