import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-panel-card-small',
  templateUrl: './panel-card-small.component.html',
  styleUrls: ['./panel-card-small.component.scss']
})
export class PanelCardSmallComponent {


  // Border color
  @Input() borderColor:string = "#45C6AA";

  // Linear gradient
  @Input() linearGradientColor1 = "#EBFAED";
  @Input() linearGradientColor2 = "rgba(235, 250, 237, 0.25)"

  // Box shadow
  @Input() boxShadowColor = "rgba(69, 198, 170, 0.20)";

  // Icone
  @Input() matIconName!: string;

  // Text
  @Input() text!: string;

  // Image
  @Input() imgSrc!: string;
  @Input() imgAlt!: string;

  constructor(private el: ElementRef) {

  }

  ngOnInit() {
    this.el.nativeElement.style.setProperty('--angle', '-180deg');
    this.el.nativeElement.style.setProperty('--border-color', this.borderColor);
    this.el.nativeElement.style.setProperty('--linear-gradient-color1', this.linearGradientColor1);
    this.el.nativeElement.style.setProperty('--linear-gradient-color2', this.linearGradientColor2);
    this.el.nativeElement.style.setProperty('--box-shadow-color', this.boxShadowColor);
  }


}
