<div [style.width]="width" [style.height]="height" class="item-container">
    <div class="item-content">
        <h1 style="font-size: 36px;">{{ letter }}</h1>
        <div *ngFor="let definition of listDefinition">
            <span class="definition-link" (click)="onClick(definition.key)">
                {{definition.translatedName}}
            </span>
        </div>
    </div>
</div>
