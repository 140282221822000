<div class="page-content">
    <!----------------------- Header ------------------------->
    <div [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'" class="page-panel">
        <!-- Image -->
        <div class="fadePanelHeader fade-panel header-images">
            <div style = "position: relative;">
                <!-- Images flottantes droite -->
                <div style="top: 50%; left: 66%; width: 7%;" class="img-absolute fade-panel fadePanelRight">
                    <img src="assets/images/landings/smart/header/light.webp" alt=""/>
                </div>
                <div style="top: 34%; left: 60%; width: 7%;" class="img-absolute fade-panel fadePanelRight">
                    <img src="assets/images/landings/smart/header/settings.webp" alt=""/>
                </div>
                <div style="top: 26%; left: 66%; width: 7%;" class="img-absolute fade-panel fadePanelRight">
                    <img src="assets/images/landings/smart/header/sensor.webp" alt=""/>
                </div>
                <div style="top: 10%; left: 60%; width: 7%;" class="img-absolute fade-panel fadePanelRight">
                    <img src="assets/images/landings/smart/header/prints.webp" alt=""/>
                </div>
                <div style="top: 0%; left: 66%; width: 7%;" class="img-absolute fade-panel fadePanelRight">
                    <img src="assets/images/landings/smart/header/camera.webp" alt=""/>
                </div>
                <!-- Images flottantes gauche -->
                <div style="top: 50%; left: 25%; width: 7%;" class="img-absolute fade-panel fadePanelLeft">
                    <img src="assets/images/landings/smart/header/share.webp" alt=""/>
                </div>
                <div style="top: 34%; left: 32%; width: 7%;" class="img-absolute fade-panel fadePanelLeft">
                    <img src="assets/images/landings/smart/header/server.webp" alt=""/>
                </div>
                <div style="top: 26%; left: 25%; width: 7%;" class="img-absolute fade-panel fadePanelLeft">
                    <img src="assets/images/landings/smart/header/download.webp" alt=""/>
                </div>
                <div style="top: 10%; left: 32%; width: 7%;" class="img-absolute fade-panel fadePanelLeft">
                    <img src="assets/images/landings/smart/header/wifi.webp" alt=""/>
                </div>
                <div style="top: 0%; left: 25%; width: 7%;" class="img-absolute fade-panel fadePanelLeft">
                    <img src="assets/images/landings/smart/header/security.webp" alt=""/>
                </div>
                <!-- Image background -->
                <img class="img-page" src="assets/images/landings/smart/header/header.webp" alt="{{'smart.caption.alt.background' | translate}}"/>
            </div> 
        </div>
        
        <!-- Titre -->
        <div class="page-panel" style="margin-top: 0rem;">
            <div class="page-panel-title">
                <h1 class="fadePanelHeader fade-panel" appColoredText [textColor]="'#074DCE'" textKey="smart.caption.title"></h1>
                <h4 class="fadePanelHeader fade-panel">{{'smart.caption.description' | translate}}</h4>
            </div>
            <!-- Bouton réserver une démo -->
            <div class="fadePanelHeader fade-panel" style="margin-top: 1rem;">
                <moffi-ui-button (eventClick)="redirectTo('contact')" buttonType="light"  label="{{'smart.caption.button' | translate}}"></moffi-ui-button>
            </div>
        </div>
    </div>

    <!----------------------- Clients ------------------------->
    <app-list-client style="margin-top: 6rem;" class="page-panel" title="{{'smart.clients.description' | translate}}" [clients]="clientsSmart"></app-list-client>


     <!----------------------- Le smart office, qu'est ce que c'est ------------------------->
     <div [style.width]="!(isHandset$ | async) ? '' : '87%'" class="page-panel-left" >
        <h2  class="fade-panel fadePanelDefinition" appColoredText [textColor]="'#074DCE'" textKey="smart.definition.title"></h2>
        <h3  class="fade-panel fadePanelDefinition" style="text-align: left;" appColoredText [textColor]="'#074DCE'" textKey="smart.definition.description"></h3>
     </div>

     <!----------------------- Bénéfices ------------------------->
     <div class="page-panel">
        <div class="page-horizontal-panel" [ngClass]="!(isHandset$ | async) ? 'benefits-panel' : 'benefits-panel-mobile'">
            <!-- Partenaires -->
            <div class="page-panel-title fade-panel fadePanelBenefices">
                <img src="assets/images/landings/page/hands/hand_step.svg" alt="icon_hand_flex"/>
                <h3 appColoredText [textColor]="'#074DCE'" textKey="smart.benefits.partners"></h3>
            </div>

            <!-- Mise en place -->
            <div class="page-panel-title fade-panel fadePanelBenefices">
                <img src="assets/images/landings/page/hands/hand_stores.svg" alt="icon_hand_flex"/>
                <h3 appColoredText [textColor]="'#074DCE'" textKey="smart.benefits.setup"></h3>
            </div>

            <!-- Simple d'utilisation -->
            <div class="page-panel-title fade-panel fadePanelBenefices">
                <img src="assets/images/landings/page/hands/hand_benefits_use.svg" alt="icon_hand_flex"/>
                <h3 appColoredText [textColor]="'#074DCE'" textKey="smart.benefits.use"></h3>
            </div>
        </div>
     </div>


     <!----------------------- Venez au bureau en toute sérénité ------------------------->
    <div class="page-panel" >
        <div class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fade-panel fadePanelBooking page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="smart.booking.title"></h2>
                <h3 class="page-horizontal-panel-item-text">{{'smart.booking.description' | translate}}</h3>
            </div>

            <!-- Image -->
            <div  [style.width]="!(isHandset$ | async) ? '30%' : '100%'"  style="height: auto; position: relative;" class="fade-panel fadePanelBooking " [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'">
                <div style="top: 42%; left: 28%; width: 90%;" class="img-absolute fade-panel fadePanelBookingImg">
                    <img src="assets/images/landings/smart/booking/{{currentLanguage}}/smart_desk.webp" alt="smart.booking.alt.desk"/>
                </div>
                <img src="assets/images/landings/smart/booking/{{currentLanguage}}/background.webp" alt="{{'smart.booking.alt.background' | translate}}"/>
            </div> 
        </div>
    </div>


    <!----------------------- Ne perdez plus votre temps à chercher où vous installer ------------------------->
    <div class="page-panel" >
        <!-- Version Desktop -->
        <div *ngIf="!(isHandset$ | async)" class="page-horizontal-panel" style="align-items: flex-start;">
            <!-- Image -->
            <div style="width: 35vw; height: auto; position: relative;" class="fade-panel fadePanelSigns" [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'">
                <img *ngIf="currentState === 'occupied'" 
                    [@changeState] 
                    class="img-absolute"
                    style="top: 0; left : 0;"
                    src="assets/images/landings/smart/signs/{{currentLanguage}}/occupied.webp" 
                    alt="{{'smart.signs.alt.meeting_room' | translate}}"/>
                <img *ngIf="currentState === 'available'" 
                    class="img-absolute"
                    style="top: 0; left : 0;"
                    [@changeState] 
                    src="assets/images/landings/smart/signs/{{currentLanguage}}/available.webp" 
                    alt="{{'smart.signs.alt.meeting_room' | translate}}"/>
            </div> 
            <!-- Texte -->
            <div class="fade-panel fadePanelSigns page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="smart.signs.title"></h2>
                <h3 class="page-horizontal-panel-item-text">{{'smart.signs.description' | translate}}</h3>
            </div>
        </div>

        <!-- Version Mobile -->
        <div *ngIf="(isHandset$ | async)" class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fade-panel fadePanelSigns page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="smart.signs.title"></h2>
                <h3 class="page-horizontal-panel-item-text">{{'smart.signs.description' | translate}}</h3>
            </div>

             <!-- Image -->
             <div class="signsImgContainer">
                <div class="signsImg fade-panel fadePanelSigns" [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'">
                    <img *ngIf="currentState === 'occupied'" 
                        [@changeState] 
                        class="img-absolute"
                        style="top: 0; left : 0; width: 100%; height: auto;"
                        src="assets/images/landings/smart/signs/{{currentLanguage}}/occupied.webp" 
                        alt="{{'smart.signs.alt.meeting_room' | translate}}"/>
                    <img *ngIf="currentState === 'available'" 
                        class="img-absolute"
                        style="top: 0; left : 0; width: 100%; height: auto;"
                        [@changeState] 
                        src="assets/images/landings/smart/signs/{{currentLanguage}}/available.webp" 
                        alt="{{'smart.signs.alt.meeting_room' | translate}}"/>
                </div> 
             </div>
             
        </div>
    </div>



    <!----------------------- Gerez tous vos accès en un tour de main ------------------------->
    <div class="page-panel" >
        <div class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fade-panel fadePanelSecurity page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="smart.security.title"></h2>
                <h3 class="page-horizontal-panel-item-text">{{'smart.security.description' | translate}}</h3>
            </div>

            <!-- Image -->
            <div style=" max-width: 90%;height: auto; position: relative; margin-left: 4rem;" class="fade-panel fadePanelSecurity" [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'">
                <!-- Mobile -->
                <div style="top: 5%; left: -13%; width: 36%;" class="img-absolute fade-panel securityImgMobile">
                    <img src="assets/images/landings/smart/security/{{currentLanguage}}/mobile.webp" alt=""/>
                </div>
                <!--- Lock -->
                <div  style="top: 40.5%; left: 21%;" class="lock-img img-absolute lockAnim"></div>
                <!-- Click -->
                <div style="top: 50%; left: 49.5%; width: 25%;" class="img-absolute fade-panel imgClick">
                    <img src="assets/images/landings/smart/security/{{currentLanguage}}/click.webp" alt=""/>
                </div>
                <img src="assets/images/landings/smart/security/background.webp" alt="{{'smart.security.alt.lock' | translate}}"/>
            </div> 
        </div>
    </div>


    <!----------------------- Nos partenaires sont experts en la matière ------------------------->
    <div class="page-panel" >
        <!-- Version desktop -->
        <div *ngIf="!(isHandset$ | async)" class="page-horizontal-panel" style="gap: 8rem;">
            <!-- Texte -->
            <div class="fade-panel fadePanelPartners page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="smart.partners.title"></h2>
                <h3 style="max-width: 90%;" class="page-horizontal-panel-item-text third-color">{{'smart.partners.description' | translate}}</h3>
                <!-- Bouton Accéder à la marketplace -->
                <div style="margin-top: 2rem;">
                    <moffi-ui-button (eventClick)="redirectTo('marketplace')" buttonType="light"  label="{{'smart.partners.button' | translate}}"></moffi-ui-button>
                </div>
            </div>

            <!-- Image -->
            <div class="fade-panel fadePanelPartners page-horizontal-panel-img" style = "margin-left: 6rem;">
                <!-- Circle Panel -->
                <app-circle-panel> 
                    <app-circle-item [imgSrc]="'/assets/images/logo/signify.svg'" [imgAlt]="'Logo Signify'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/philips.svg'" [imgAlt]="'Logo Philips'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/prodvx.svg'" [imgAlt]="'Logo Prodvx'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/merciyanis.svg'" [imgAlt]="'Logo Merciyanis'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/welcomr.svg'" [imgAlt]="'Logo Welcomr'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/zebre.svg'" [imgAlt]="'Logo Zebre'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                </app-circle-panel>
            </div> 
        </div>
        <!-- Version mobile -->
        <div *ngIf="(isHandset$ | async)" class="page-horizontal-panel">
            <!-- Image -->
            <div class="fade-panel fadePanelPartners page-horizontal-panel-img">
                <!-- Circle Panel -->
                <app-circle-panel>
                    <app-circle-item [imgSrc]="'/assets/images/logo/signify.svg'" [imgAlt]="'Logo Signify'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/philips.svg'" [imgAlt]="'Logo Philips'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/prodvx.svg'" [imgAlt]="'Logo Prodvx'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/merciyanis.svg'" [imgAlt]="'Logo Merciyanis'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/welcomr.svg'" [imgAlt]="'Logo Welcomr'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/zebre.svg'" [imgAlt]="'Logo Zebre'" [borderRadius]="'10%'" [isSquare]="true"></app-circle-item>
                </app-circle-panel>
            </div>
            <!-- Texte -->
            <div class="fade-panel fadePanelPartners page-panel">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="smart.partners.title"></h2>
                <h3 class="third-color">{{'smart.partners.description' | translate}}</h3>
                <!-- Bouton Accéder à la marketplace -->
                <div style="margin-top: 2rem;">
                    <moffi-ui-button (eventClick)="redirectTo('marketplace')" buttonType="light"  label="{{'smart.partners.button' | translate}}"></moffi-ui-button>
                </div>
            </div>
        </div>
    </div>


    <!----------------------- Accompagnement ------------------------->
    <div class="page-panel" >
        <!-- title -->
        <div class="page-panel-title">
            <div class="fade-panel fadePanelSupport">
                <img src="assets/images/landings/page/hands/hand_support.svg" alt="icon_hand_support"/>
                <h2 appColoredText [textColor]="'#074DCE'" textKey="smart.support.title"></h2>
            </div>
            <h4 class="fade-panel fadePanelSupport">{{'smart.support.description' | translate}}</h4>
        </div>

        <div [ngClass]="!(isHandset$ | async) ? 'smart-steps' : 'smart-steps-mobile'" class="fade-panel fadePanelSupport">
            <app-panel-step orientation="horizontal">
                <!-- Step 1 : Point sur le projet Flex Office -->
                <app-step stepIconSrc="assets/images/landings/page/steps/screen.svg"
                stepIconAlt="icon_screen"
                title="{{'smart.support.step1.title' | translate}}"
                description="{{'smart.support.step1.description' | translate}}"></app-step>
                <!-- Step 2 : Expert de l'outil -->
                <app-step stepIconSrc="assets/images/landings/page/steps/hat.svg"
                stepIconAlt="icon_hat"
                title="{{'smart.support.step2.title' | translate}}"
                description="{{'smart.support.step2.description' | translate}}"></app-step>
                <!-- Step 3 : Ensuite -->
                <app-step stepIconSrc="assets/images/landings/page/steps/notes.svg"
                stepIconAlt="icon_notes"
                title="{{'smart.support.step3.title' | translate}}"
                description="{{'smart.support.step3.description' | translate}}"></app-step>
            </app-panel-step>
        </div>

        <!-- Button Je me lance-->
        <div class="page-panel-title">
            <h4 style="font-size: 16px;" class="fade-panel fadePanelSupport">{{'smart.support.premium' | translate}}</h4>
            <!-- Bouton réserver une démo -->
            <div class="fade-panel fadePanelSupport" style="margin-top: 1rem;">
                <moffi-ui-button (eventClick)="redirectTo('contact')" buttonType="light"  label="{{'smart.support.button' | translate}}"></moffi-ui-button>
            </div>
        </div>
    </div>


     <!----------------------- Ambasadeurs ------------------------->
     <div class="page-panel" [style]="!(isHandset$ | async) ? 'margin-bottom: 8rem;' : 'margin-bottom: 1rem;'" >
        <!-- title -->
        <div class="page-panel-title">
            <div class="fade-panel fadePanelTestimony">
                <img src="assets/images/landings/page/hands/hand_testimony.svg" alt="icon_hand_testimony"/>
                <h2 appColoredText [textColor]="'#074DCE'" textKey="smart.testimony.title"></h2>
            </div>
            <h4 class="fade-panel fadePanelTestimony">{{'smart.testimony.description' | translate}}</h4>
        </div>

        <!-- Panel cards-->
        <div [ngClass]="!(isHandset$ | async) ? 'page-panel-cards' : 'page-panel-cards-mobile'">
            <div class="fade-panel fadePanelTestimony page-panel-card">
                <!-- ShowRoom -->
                <app-panel-card-large 
                    iconSrc="assets/images/icon/icon_quote/quote_cyan.svg" 
                    iconAlt="quote_cyan"
                    text="{{'smart.testimony.first.text' | translate}}"
                    imgSrc = "assets/images/illustrations/testimonies/show_room.webp"
                    imgAlt="ShowRoom"
                    pTitle = "{{'smart.testimony.first.name' | translate}}"
                    pText = "{{'smart.testimony.first.position' | translate}}"
                    borderColor="#94ECFF"
                    linearGradientColor1="#E4FAFF"
                    linearGradientColor2="rgba(228, 250, 255, 0.25)"
                    boxShadowColor="rgba(69, 198, 170, 0.20)"
                    linkColor="#00ACD1"></app-panel-card-large>
            </div>
            <div class="fade-panel fadePanelTestimony page-panel-card">
                <!-- SanteVet -->
                <app-panel-card-large
                    iconSrc="assets/images/icon/icon_quote/quote_purple.svg" 
                    iconAlt="quote_purple"
                    text="{{'smart.testimony.second.text' | translate}}"
                    imgSrc = "assets/images/illustrations/testimonies/santevet.webp"
                    imgAlt="SanteVet"
                    pTitle = "{{'smart.testimony.second.name' | translate}}"
                    pText = "{{'smart.testimony.second.position' | translate}}"
                    borderColor="#B494FF"
                    linearGradientColor1="#EFEBFC"
                    linearGradientColor2="rgba(239, 235, 252, 0.25)"
                    boxShadowColor="rgba(180, 148, 255, 0.20)"
                    linkColor="#7E47FF"></app-panel-card-large>
            </div>
            <div class="fade-panel fadePanelTestimony page-panel-card">
                <!-- Cyrillus -->
                <app-panel-card-large 
                    iconSrc="assets/images/icon/icon_quote/quote_pink.svg" 
                    iconAlt="quote_pink"
                    text="{{'smart.testimony.third.text' | translate}}"
                    imgSrc = "assets/images/illustrations/testimonies/cyrillus.webp"
                    imgAlt="Le Village"
                    pTitle = "{{'smart.testimony.third.name' | translate}}"
                    pText = "{{'smart.testimony.third.position' | translate}}"
                    borderColor="#FF94F4"
                    linearGradientColor1="#FFECFD"
                    linearGradientColor2="rgb(255, 236, 253, 0.25)"
                    boxShadowColor="rgba(255, 148, 244, 0.20)"
                    linkColor="#CB62C0"></app-panel-card-large>
            </div>
        </div>
    </div>

    <!----------------------- Bandeau démo ------------------------->
    <div style="margin-top: 0rem;" class="page-demo-panel" >

        <!-- Background -->
        <div class="page-demo-background">
            
            <div style="width: 50rem; height: 40rem; top: -61%; left:76%;" class="gradient-circle blue-gradient"></div>
            <div style="top: -8%; left:80%;transform: rotate(140deg);opacity:0.9;" class="gradient-small-circle pink-gradient"></div>
            <div style="top: -13%; left:82%;transform: rotate(140deg); filter: blur(5rem);" class="gradient-small-circle yellow-gradient"></div>
            <div style="width: 50rem; height: 40rem; top: 40%; left:65%;" class="gradient-circle blue-gradient"></div>
            <div style="top: 39%; left:81%;opacity: 0.8;" class="gradient-small-circle green-gradient"></div>
            <div style="width: 50rem; height: 50rem; top: 76%; left:73%; opacity:0.7;" class="gradient-circle purple-gradient"></div> 
            
        </div>

        <!-- title -->
        <div class="page-panel-title">
            <img src="assets/images/landings/page/hands/hand_demo.svg" alt="icon_hand_demo"/>
            <h2 class="dark-color">{{'smart.cta.title' | translate}}</h2>
            <h4 class="dark-color" appColoredText [textColor]="'#7285D8'" textKey="smart.cta.description"></h4>
        </div>

        <!-- Bouton démo -->
        <div class="smart-demo-button">
            <moffi-ui-button (eventClick)="redirectTo('pricing')" buttonType="dark"  label="{{'smart.cta.button.offers' | translate}}"></moffi-ui-button>
            <moffi-ui-button (eventClick)="redirectTo('contact')" buttonType="light"  label="{{'smart.cta.button.demo' | translate}}"></moffi-ui-button>
        </div>
        
    </div>


</div>
