<div class="card-container" (click)="openUrl(link)">
    <div class="card-content">
        <!-- Image -->
        <img class="card-img" src="assets/images/landings/glossary/articles/{{key}}.webp" alt="{{key}}">
        <div class="card-content-text">
            <div class="card-text">
                <!-- Title -->
                <h3 style="font-weight: 600;margin-bottom: 1rem;">{{title | translate}}</h3>
                <!-- Text -->
                <h3 style="font-size: 18px;">{{text | translate}}</h3>
            </div>
            <div class="card-text card-link">
                <span>{{"blog.card.button" | translate}}</span>
                <mat-icon fontIcon="arrow_forward"></mat-icon>
            </div>
        </div>
    </div>
</div>
