<div class="page-content">
    <!----------------------- Header ------------------------->
    <div [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'" class="page-panel">
        <!-- Image -->
        <div class="fadePanelHeader fade-panel header-images">
            <div style = "position: relative;">
                <!-- Image Statistics-->
                <div class="img-absolute headerImage header-image header-image-statistics">
                    <img src="assets/images/landings/remote/header/{{currentLanguage}}/statistics.webp" alt="{{'remote.caption.alt.stats' | translate}}"/>
                </div>
                <!-- Image Preferences-->
                <div class="img-absolute headerImage header-image header-image-filters">
                    <img src="assets/images/landings/remote/header/{{currentLanguage}}/filters.webp" alt="{{'remote.caption.alt.filters' | translate}}"/>
                </div>
                <img src="assets/images/landings/remote/header/{{currentLanguage}}/header.webp" alt="{{'remote.caption.alt.background' | translate}}"/>
            </div> 
        </div>
        
        <!-- Titre -->
        <div class="page-panel" style="margin-top: 0rem;">
            <div class="page-panel-title">
                <h1 class="fadePanelHeader fade-panel" appColoredText [textColor]="'#074DCE'" textKey="remote.caption.title"></h1>
                <h4 class="fadePanelHeader fade-panel">{{'remote.caption.description' | translate}}</h4>
            </div>
            <!-- Bouton réserver une démo -->
            <div class="fadePanelHeader fade-panel" style="margin-top: 1rem;">
                <moffi-ui-button (eventClick)="redirectTo('contact')" buttonType="light"  label="{{'home.caption.button' | translate}}"></moffi-ui-button>
            </div>
        </div>
    </div>


    <!----------------------- Clients ------------------------->
    <app-list-client class="page-panel" title="{{'remote.clients.description' | translate}}" [clients]="clients"></app-list-client>


    <!----------------------- Vision du télétravail ------------------------->
    <div class="page-panel" >
        <div class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fadePanelVision fade-panel page-horizontal-panel-item">
                <h2  appColoredText [textColor]="'#074DCE'" textKey="remote.rules.title"></h2>
                <h3 class="page-horizontal-panel-item-text">{{'remote.rules.description' | translate}}</h3>
            </div>

            <!-- Image -->
            <div class="fadePanelVision fade-panel" [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'" style = "position: relative;">
                <!-- Image Radio Bouton Lundi -->
                <div class="fadePanelVisionImg fade-panel img-absolute vision-image-monday">
                    <img src="assets/images/landings/remote/vision/radio-button.webp" alt="radio-button-1"/>
                </div>
                <!-- Image Radio Bouton Mardi -->
                <div class="fadePanelVisionImg fade-panel img-absolute vision-image-tuesday">
                    <img src="assets/images/landings/remote/vision/radio-button.webp" alt="radio-button-2"/>
                </div>
                <!-- Image Radio Bouton Mercredi -->
                <div class="fadePanelVisionImg fade-panel img-absolute vision-image-wednesday">
                    <img src="assets/images/landings/remote/vision/radio-button.webp" alt="radio-button-3"/>
                </div>
                <!-- Image Radio Bouton Jeudi -->
                <div class="fadePanelVisionImg fade-panel img-absolute vision-image-thursday">
                    <img src="assets/images/landings/remote/vision/radio-button.webp" alt="radio-button-4"/>
                </div>
                <!-- Image Radio Bouton Vendredi -->
                <div class="fadePanelVisionImg fade-panel img-absolute vision-image-friday">
                    <img src="assets/images/landings/remote/vision/radio-button.webp" alt="radio-button-5"/>
                </div>
                <!-- Image Rule-->
                <div class="fadePanelVisionImg fade-panel img-absolute vision-image-rule">
                    <img src="assets/images/landings/remote/vision/{{currentLanguage}}/rule.webp" alt="{{'remote.rules.alt.button' | translate}}"/>
                </div>
                <img src="assets/images/landings/remote/vision/{{currentLanguage}}/background.webp" alt="{{'remote.rules.alt.background' | translate}}"/>
            </div> 
        </div>
    </div>

    <!----------------------- Un atout marque employeur ------------------------->
    <div class="page-panel" >
        <!-- Version desktop -->
        <div *ngIf="!(isHandset$ | async)" class="page-horizontal-panel" style="gap: 8rem;">
            <!-- Image -->
            <div class="fadePanelHc fade-panel page-horizontal-panel-img" style = "position: relative;">
                <!-- Button -->
                <div class="img-absolute hr-image-button">
                    <img src="assets/images/landings/remote/hr/button.webp" alt="button"/>
                </div>
                <!-- Button Click-->
                <div class="fadePanelHcImgClick fade-panel img-absolute hr-image-button">
                    <img src="assets/images/landings/remote/hr/button-selected.webp" alt="button-selected"/>
                </div>
                <!-- Image Curseur souris fadePanelHcImgCursor fade-panel-->
                <div class="hcImgCursor img-absolute hr-image-cursor">
                    <img src="assets/images/landings/remote/hr/cursor.webp" alt="cursor-pointer"/>
                </div>
                <!-- Image Workplace-->
                <div class="fadePanelHcImgWorkspace fade-panel img-absolute hr-image-workplace">
                    <img src="assets/images/landings/remote/hr/{{currentLanguage}}/workplace.webp" alt="{{'remote.hr.alt.booking' | translate}}"/>
                </div>
                <img src="assets/images/landings/remote/hr/{{currentLanguage}}/background.webp" alt="{{'remote.hr.alt.background' | translate}}"/>
            </div> 
            <!-- Texte -->
            <div class="fadePanelHc fade-panel page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="remote.hr.title"></h2>
                <h3 class="page-horizontal-panel-item-text">{{'remote.hr.description' | translate}}</h3>
            </div>
        </div>
        <!-- Version mobile -->
        <div *ngIf="(isHandset$ | async)" class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fadePanelHc fade-panel page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="remote.hr.title"></h2>
                <h3 class="page-horizontal-panel-item-text">{{'remote.hr.description' | translate}}</h3>
            </div>

            <!-- Image -->
            <div class="fadePanelHc fade-panel page-horizontal-panel-img-margin" style = "position: relative;">
                <!-- Button -->
                <div class="img-absolute hr-image-button">
                    <img src="assets/images/landings/remote/hr/button.webp" alt="button"/>
                </div>
                <!-- Button Click-->
                <div class="fadePanelHcImgClick fade-panel img-absolute hr-image-button">
                    <img src="assets/images/landings/remote/hr/button-selected.webp" alt="button-selected"/>
                </div>
                <!-- Image Curseur souris fadePanelHcImgCursor fade-panel-->
                <div class="hcImgCursor img-absolute hr-image-cursor">
                    <img src="assets/images/landings/remote/hr/cursor.webp" alt="cursor-pointer"/>
                </div>
                <!-- Image Workplace-->
                <div class="fadePanelHcImgWorkspace fade-panel img-absolute hr-image-workplace">
                    <img src="assets/images/landings/remote/hr/{{currentLanguage}}/workplace.webp" alt="{{'remote.hr.alt.booking' | translate}}"/>
                </div>
                <img src="assets/images/landings/remote/hr/{{currentLanguage}}/background.webp" alt="{{'remote.hr.alt.background' | translate}}"/>
            </div> 
        </div>
    </div>

    <!----------------------- Suivez le télétravail en un coup d'oeil ------------------------->
    <div class="page-panel" >
        <div class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fadePanelManage fade-panel page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="remote.manage.title"></h2>
                <h3 class="page-horizontal-panel-item-text">{{'remote.manage.description' | translate}}</h3>
            </div>

            <!-- Image -->
            <div class="fadePanelManage fade-panel" [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'" style = "position: relative;">
                <!-- Image Déclarer Utilisateur-->
                <div class="fadePanelManageImg fade-panel img-absolute manage-image-declare-user">
                    <img src="assets/images/landings/remote/manage/{{currentLanguage}}/declare-user.webp" alt="{{'remote.manage.alt.button' | translate}}"/>
                </div>
                <img src="assets/images/landings/remote/manage/{{currentLanguage}}/background.webp" alt="{{'remote.manage.alt.background' | translate}}"/>
            </div> 
        </div>
    </div>


    <!----------------------- Redonnez de la valeur au présentiel ------------------------->
    <div class="page-panel" >
        <!-- Version desktop -->
        <div *ngIf="!(isHandset$ | async)" class="page-horizontal-panel" style="gap: 8rem;">
            <!-- Image -->
            <div class="fadePanelValue fade-panel page-horizontal-panel-img" style = "position: relative;">
                <!-- Image Confirmation -->
                <div class="fadePanelValueImg fade-panel img-absolute value-image-confirmation">
                    <img src="assets/images/landings/remote/value/{{currentLanguage}}/confirmation.webp" alt="{{'remote.value.alt.saved' | translate}}"/>
                </div>
                <!-- Image Invitation -->
                <div class="fadePanelValueImg fade-panel img-absolute value-image-invitation">
                    <img src="assets/images/landings/remote/value/{{currentLanguage}}/invitation.webp" alt="{{'remote.value.alt.button' | translate}}"/>
                </div>
                <img src="assets/images/landings/remote/value/{{currentLanguage}}/background.webp" alt="{{'remote.value.alt.background' | translate}}"/>
            </div> 
            <!-- Texte -->
            <div class="fadePanelValue fade-panel page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="remote.value.title"></h2>
                <h3 class="page-horizontal-panel-item-text">{{'remote.value.description' | translate}}</h3>
            </div>
        </div>
        <!-- Version mobile -->
        <div *ngIf="(isHandset$ | async)" class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fadePanelValue fade-panel page-horizontal-panel-item">
                <h2  appColoredText [textColor]="'#074DCE'" textKey="remote.value.title"></h2>
                <h3 class="page-horizontal-panel-item-text">{{'remote.value.description' | translate}}</h3>
            </div>

            <!-- Image -->
            <div class="fadePanelValue fade-panel page-horizontal-panel-img-margin" style = "position: relative;">
                <!-- Image Confirmation -->
                <div class="fadePanelValueImg fade-panel img-absolute value-image-confirmation">
                    <img src="assets/images/landings/remote/value/{{currentLanguage}}/confirmation.webp" alt="{{'remote.value.alt.saved' | translate}}"/>
                </div>
                <!-- Image Invitation -->
                <div class="fadePanelValueImg fade-panel img-absolute value-image-invitation">
                    <img src="assets/images/landings/remote/value/{{currentLanguage}}/invitation.webp" alt="{{'remote.value.alt.button' | translate}}"/>
                </div>
                <img src="assets/images/landings/remote/value/{{currentLanguage}}/background.webp" alt="{{'remote.value.alt.background' | translate}}"/>
            </div> 
        </div>
    </div>


    <!----------------------- Le télétravail ? Mieux avec Moffi ------------------------->
    <div class="page-panel">
        <!-- title -->
        <div class="page-panel-title">
            <div class="fadePanelAssets fade-panel">
                <img src="assets/images/landings/page/hands/hand_assets.svg" alt="icon_hand_flex"/>
                <h2 appColoredText [textColor]="'#074DCE'" textKey="remote.assets.title"></h2>
            </div>
        </div>
        <!-- Tableau Version Desktop -->
        <div *ngIf="!(isHandset$ | async)" class="fadePanelAssets fade-panel" style="margin-top: 3rem; max-width: 95%;">
            <app-simple-table [data]="assetsTableData">
                <!-- Header -->
                <ng-template let-item #headerTemplate>
                    <tr>
                        <th class="assets-table-header" id="asset"></th>
                        <th class="assets-table-header assets-table-border-left" id="withoutMoffi">{{'remote.assets.without_moffi.title' | translate}}</th>
                        <th class="assets-table-header assets-table-border-left" id="withMoffi">{{'remote.assets.with_moffi.title' | translate}}</th>
                  </tr>
                </ng-template>
                <!-- Colonnes -->
                <ng-template let-item #columnTemplate>
                    <td class="assets-table-td assets-table-td-asset" appColoredText [textColor]="'#074DCE'" [textKey]="item.assetKey"></td>
                    <td class="assets-table-td assets-table-border-left" >
                        <mat-icon *ngIf="!item.withoutMoffi" style="color:#C5CBEC;" fontIcon="close"></mat-icon>
                        <mat-icon *ngIf="item.withoutMoffi" style="color:#45C6AA;" fontIcon="done"></mat-icon>
                    </td>
                    <td class="assets-table-td assets-table-border-left" >
                        <mat-icon *ngIf="!item.withMoffi" style="color:#C5CBEC;" fontIcon="close"></mat-icon>
                        <mat-icon *ngIf="item.withMoffi" style="color:#45C6AA;" fontIcon="done"></mat-icon>
                    </td>
                </ng-template>
            </app-simple-table>
        </div>
        <!-- Tableau Version Mobile -->
        <div *ngIf="(isHandset$ | async)" class="fadePanelAssets fade-panel" style="margin-top: 3rem; max-width: 95%;">
            <app-simple-table [padding]="'1rem'" [data]="assetsTableDataMobile">
                <!-- Header -->
                <ng-template let-item #headerTemplate>
                    <tr>
                        <th class="assets-table-header-mobile" id="withoutMoffi">{{'remote.assets.without_moffi.title' | translate}}</th>
                        <th class="assets-table-header-mobile" id="withMoffi">{{'remote.assets.with_moffi.title' | translate}}</th>
                  </tr>
                </ng-template>
                <!-- Colonnes -->
                <ng-template let-item #columnTemplate>
                    <td *ngIf="item.assetKey" colspan="2" class="assets-table-td-mobile" appColoredText [textColor]="'#074DCE'" [textKey]="item.assetKey"></td>

                    <td *ngIf="!item.assetKey" [ngClass]="!item.isLastElement ? 'assets-table-border-bottom' : ''" class="assets-table-td-mobile" >
                        <mat-icon *ngIf="!item.withoutMoffi" style="color:#C5CBEC;" fontIcon="close"></mat-icon>
                        <mat-icon *ngIf="item.withoutMoffi" style="color:#45C6AA;" fontIcon="done"></mat-icon>
                    </td>
                    <td *ngIf="!item.assetKey" [ngClass]="!item.isLastElement ? 'assets-table-border-bottom' : ''" class="assets-table-td-mobile" >
                        <mat-icon *ngIf="!item.withMoffi" style="color:#C5CBEC;" fontIcon="close"></mat-icon>
                        <mat-icon *ngIf="item.withMoffi" style="color:#45C6AA;" fontIcon="done"></mat-icon>
                    </td>
                </ng-template>
            </app-simple-table>
        </div>
        <!-- Bouton J'en profite -->
        <div class="fadePanelAssets fade-panel" style="margin-top: 2rem;">
            <moffi-ui-button (eventClick)="redirectTo('contact')" buttonType="light"  label="{{'remote.assets.button' | translate}}"></moffi-ui-button>
        </div>
    </div>


    <!----------------------- Dites au revoir à la double saisie ------------------------->
    <div class="page-panel" >
        <!-- Version desktop -->
        <div *ngIf="!(isHandset$ | async)" class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fadePanelHris fade-panel page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="remote.hris.title"></h2>
                <h3 class="page-horizontal-panel-item-text third-color">{{'remote.hris.description' | translate}}</h3>
                <!-- Bouton Accéder à la marketplace -->
                <div style="margin-top: 2rem;">
                    <moffi-ui-button (eventClick)="redirectTo('marketplace')" buttonType="light"  label="{{'remote.hris.button' | translate}}"></moffi-ui-button>
                </div>
            </div>

            <!-- Image -->
            <div class="fadePanelHris fade-panel page-horizontal-panel-img" style = "margin-left: 3rem;">
                <!-- Circle Panel -->
                <app-circle-panel> 
                    <app-circle-item [imgSrc]="'/assets/images/logo/personio.webp'" [imgAlt]="'Logo Personio'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/bamboo.svg'" [imgAlt]="'Logo Bamboo'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/factorial.svg'" [imgAlt]="'Logo Factorial'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/bob.svg'" [imgAlt]="'Logo Bob'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/brighthr.svg'" [imgAlt]="'Logo Bright HR'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/zapier.svg'" [imgAlt]="'Logo Zapier'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/adp.svg'" [imgAlt]="'Logo ADP'" [borderRadius]="'100%'"></app-circle-item>
                </app-circle-panel>
            </div> 
        </div>
        <!-- Version mobile -->
        <div *ngIf="(isHandset$ | async)" class="page-horizontal-panel">
            <!-- Image -->
            <div class="fadePanelHris fade-panel page-horizontal-panel-img">
                <!-- Circle Panel -->
                <app-circle-panel>
                    <app-circle-item [imgSrc]="'/assets/images/logo/personio.webp'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/bamboo.svg'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/factorial.svg'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/bob.svg'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/brighthr.svg'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/zapier.svg'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/adp.svg'" [borderRadius]="'100%'"></app-circle-item>
                    <app-circle-item [imgSrc]="'/assets/images/logo/lucca.svg'" [borderRadius]="'100%'"></app-circle-item>
                </app-circle-panel>
            </div>
            <!-- Texte -->
            <div class="fadePanelHris fade-panel page-panel">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="remote.hris.title"></h2>
                <h3 class="third-color">{{'remote.hris.description' | translate}}</h3>
                <!-- Bouton Accéder à la marketplace -->
                <div class="fadePanelAssets fade-panel" style="margin-top: 2rem;">
                    <moffi-ui-button (eventClick)="redirectTo('marketplace')" buttonType="light"  label="{{'remote.hris.button' | translate}}"></moffi-ui-button>
                </div>
            </div> 
        </div>
    </div>


    <!----------------------- Ambasadeurs ------------------------->
    <div class="page-panel" [style]="!(isHandset$ | async) ? 'margin-bottom: 8rem;' : 'margin-bottom: 1rem;'" >
        <!-- title -->
        <div class="page-panel-title">
            <div class="fadePanelTestimony fade-panel">
                <img src="assets/images/landings/page/hands/hand_testimony.svg" alt="icon_hand_testimony"/>
                <h2 appColoredText [textColor]="'#074DCE'" textKey="remote.testimony.title"></h2>
            </div>
            <h4 class="fadePanelTestimony fade-panel">{{'remote.testimony.description' | translate}}</h4>
        </div>

        <!-- Panel cards-->
        <div [ngClass]="!(isHandset$ | async) ? 'page-panel-cards' : 'page-panel-cards-mobile'">
            <div class="fadePanelTestimony page-panel-card fade-panel">
                <!-- Citeo -->
                <app-panel-card-large 
                    iconSrc="assets/images/icon/icon_quote/quote_cyan.svg" 
                    iconAlt="quote_cyan"
                    text="{{'remote.testimony.first.text' | translate}}"
                    imgSrc = "assets/images/illustrations/testimonies/show_room.webp"
                    imgAlt="Citeo"
                    pTitle = "{{'remote.testimony.first.name' | translate}}"
                    pText = "{{'remote.testimony.first.position' | translate}}"
                    borderColor="#94ECFF"
                    linearGradientColor1="#E4FAFF"
                    linearGradientColor2="rgba(228, 250, 255, 0.25)"
                    boxShadowColor="rgba(69, 198, 170, 0.20)"
                    linkColor="#00ACD1"></app-panel-card-large>
            </div>
            <div class="fadePanelTestimony page-panel-card fade-panel">
                <!-- Effy -->
                <app-panel-card-large
                    iconSrc="assets/images/icon/icon_quote/quote_purple.svg" 
                    iconAlt="quote_purple"
                    text="{{'remote.testimony.second.text' | translate}}"
                    imgSrc = "assets/images/illustrations/testimonies/santevet.webp"
                    imgAlt="Effy"
                    pTitle = "{{'remote.testimony.second.name' | translate}}"
                    pText = "{{'remote.testimony.second.position' | translate}}"
                    borderColor="#B494FF"
                    linearGradientColor1="#EFEBFC"
                    linearGradientColor2="rgba(239, 235, 252, 0.25)"
                    boxShadowColor="rgba(180, 148, 255, 0.20)"
                    linkColor="#7E47FF"></app-panel-card-large>
            </div>
            <div class="fadePanelTestimony page-panel-card fade-panel">
                <!-- Le Village -->
                <app-panel-card-large 
                    iconSrc="assets/images/icon/icon_quote/quote_pink.svg" 
                    iconAlt="quote_pink"
                    text="{{'remote.testimony.third.text' | translate}}"
                    imgSrc = "assets/images/illustrations/testimonies/cyrillus.webp"
                    imgAlt="Le Village"
                    pTitle = "{{'remote.testimony.third.name' | translate}}"
                    pText = "{{'remote.testimony.third.position' | translate}}"
                    borderColor="#FF94F4"
                    linearGradientColor1="#FFECFD"
                    linearGradientColor2="rgb(255, 236, 253, 0.25)"
                    boxShadowColor="rgba(255, 148, 244, 0.20)"
                    linkColor="#CB62C0"></app-panel-card-large>
            </div>
        </div>
    </div>

    <!----------------------- Bandeau démo ------------------------->
    <div style="margin-top: 0rem;" class="page-demo-panel" >

        <!-- Background -->
        <div class="page-demo-background">
            
            <div style="width: 50rem; height: 40rem; top: -61%; left:76%;" class="gradient-circle blue-gradient"></div>
            <div style="top: -8%; left:80%;transform: rotate(140deg);opacity:0.9;" class="gradient-small-circle pink-gradient"></div>
            <div style="top: -13%; left:82%;transform: rotate(140deg);" class="gradient-small-circle yellow-gradient"></div>
            <div style="width: 50rem; height: 40rem; top: 40%; left:65%;" class="gradient-circle blue-gradient"></div>
            <div style="top: 45%; left:72%;opacity: 0.8;" class="gradient-small-circle green-gradient"></div>
            <div style="width: 50rem; height: 50rem; top: 42%; left:85%; opacity:0.7;" class="gradient-circle purple-gradient"></div> 
            
        </div>


        <!-- title -->
        <div class="page-panel-title">
            <img src="assets/images/landings/page/hands/hand_demo.svg" alt="icon_hand_demo"/>
            <h2 class="dark-color">{{'remote.cta.title' | translate}}</h2>
            <h4 class="dark-color" appColoredText [textColor]="'#7285D8'" textKey="remote.cta.description"></h4>
        </div>

        <!-- Bouton démo -->
        <moffi-ui-button (eventClick)="redirectTo('contact')" buttonType="dark"  label="{{'remote.cta.button' | translate}}"></moffi-ui-button>
    </div>


</div>
