<div class="page-content">

     <!----------------------- Bouton Retour ------------------------->
     <div [ngClass]="!(isHandset$ | async) ? 'return-container' : 'return-container-mobile'" class="page-panel fadePanelHeader fade-panel" (click)="redirectTo('glossary')">
        <mat-icon fontIcon="arrow_back"></mat-icon>
        {{'global.back' | translate}}
    </div>

    <!----------------------- Header ------------------------->
    <div [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'" class="page-panel">
        <!-- Title -->
        <div class="page-panel" style="margin-top: 2rem;">
            <!-- Tag -->
            <div class="fadePanelHeader fade-panel definition-tag-container">
                <div class="definition-tag">{{"glossary.category.name" | translate}}</div>
            </div>
            <!-- Title -->
            <div class="page-panel-title" [style]="(isHandset$) ? 'margin-top: 1rem;' : ''">
                <h1 class="fadePanelHeader fade-panel" >
                    <span>{{'glossary.caption.title.definition' | translate}} </span>
                    <span style="color: #074DCE;">{{(modelDefinition?.name ?? '') | translate}}</span>
                </h1>
            </div>
        </div>
    </div>

    <!----------------------- Definitions ------------------------->
    <div class="page-panel" [style]=" !(isHandset$ | async) ? 'margin-top: 6rem;' : 'margin-top: 3rem;'" >
         <!-- Definition -->
         <div [ngClass]="!(isHandset$ | async) ? 'definition-container' : 'definition-container-mobile'" class="fadePanelPart fade-panel definition-container">
            <h3 class="definition-text">{{(modelDefinition?.template?.definition ?? '') | translate}}</h3>
         </div>
        <div class="page-horizontal-panel" style="align-content: flex-start;align-items: flex-start;">
            <!-- Texte -->
             <div [ngClass]="!(isHandset$ | async) ? 'parts-container' : 'parts-container-mobile'">
                <div *ngFor="let part of modelDefinition?.template?.parts" class="part-content fadePanelPart fade-panel">
                    <!-- Title -->
                    <h2>{{part.title | translate}}</h2>
                    <!-- Text 1 -->
                    <h3 class="definition-text" *ngIf="part.text1" [innerHTML]="part.text1 | translate | highlight"></h3>
                    <!-- List -->
                    <ul *ngIf="part.list" class="definition-list">
                        <li *ngFor="let item of part.list">
                            <h3 class="definition-text" [innerHTML]="item | translate | highlight"></h3>
                        </li>
                    </ul>
                    <!-- Ordered List -->
                    <ol *ngIf="part.orderedList" style="padding-inline: unset;">
                        <li *ngFor="let item of part.orderedList">
                            <h3 class="definition-text" [innerHTML]="item | translate | highlightAndMargin"></h3>
                        </li>
                    </ol>
                    <!-- Paragraphes -->
                    <div *ngFor="let paragraph of part.paragraphs">
                        <!-- Title -->
                        <h3 style="font-weight: 600;margin-bottom: 1rem;font-size:22px;">{{paragraph.title | translate}}</h3>
                        <!-- Text 1 -->
                        <h3 class="definition-text" *ngIf="paragraph.text1" [innerHTML]="paragraph.text1 | translate | highlight"></h3>
                        <!-- List -->
                        <ul *ngIf="paragraph.list" class="definition-list">
                            <li *ngFor="let item of paragraph.list">
                                <h3 class="definition-text" [innerHTML]="item | translate | highlight"></h3>
                            </li>
                        </ul>
                        <!-- Ordered List -->
                        <ol *ngIf="paragraph.orderedList" style="padding-inline: unset;">
                            <li *ngFor="let item of paragraph.orderedList">
                                <h3 class="definition-text" [innerHTML]="item | translate | highlightAndMargin"></h3>
                            </li>
                        </ol>
                        <!-- Text 2 -->
                        <h3 class="definition-text" *ngIf="paragraph.text2" [innerHTML]="paragraph.text2 | translate | highlight"></h3>
                    </div>
                    <!-- Text 2 -->
                    <h3 class="definition-text" *ngIf="part.text2" [innerHTML]="part.text2 | translate | highlight"></h3>
                </div>
             </div>

             <!-- Cards -->
              <div [ngClass]="!(isHandset$ | async) ? 'definition-cards' : 'definition-cards-mobile'">
                <!-- Other definitions -->
                <div class="card-other-container fadePanelCard fade-panel">
                    <div class="card-other-content">
                        <!-- Image Hands -->
                        <img src="assets/images/landings/page/hands/hand_card_game.svg" alt="icon_hand_card_game"/>
                        <!-- Title-->
                        <h3 style="font-weight: 600;">{{"glossary.card.definitions.title" | translate}}</h3>
                        <!-- Other definitions-->
                        <div style="margin-top: 1rem;">
                            <div *ngFor="let other of modelDefinition?.template?.otherDefinitionsModel" style="margin-top: 0.3rem;">
                                <span class="definition-link" (click)="redirectTo('glossary','', other.key)">{{other.name | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Flex Office project -->
                <div class="card-other-container fadePanelCard fade-panel">
                    <div class="card-other-content">
                        <!-- Image Hands -->
                        <img src="assets/images/landings/page/hands/hand_diamond.svg" alt="icon_hand_card_game"/>
                        <!-- Title-->
                        <h3 style="font-weight: 600;">{{"glossary.card.cta.title" | translate}}</h3>
                        <!-- Text -->
                        <h3 style="margin-top: 1rem;font-size:16px;">{{"glossary.card.cta.text" | translate}}</h3>
                        <!-- Bouton Réserver démo-->
                        <moffi-ui-button
                        class="demo-button"
                        (eventClick)="redirectTo('contact')"
                        buttonType="light"
                        width="100%"
                        label="{{ 'glossary.card.cta.button' | translate }}"
                      ></moffi-ui-button>
                    </div>
                </div>
              </div>
        </div>
    </div>

    <!----------------------- Articles ------------------------->
    <div class="page-panel" style="align-content: flex-start;align-items: flex-start;" [style]="!(isHandset$ | async) ? 'max-width: 72%;margin-top: 6rem;' : 'max-width: 90%;margin-top: 3rem;'">
        <!-- Title -->
        <h2 class="fadePanelArticles fade-panel" style="text-align: left;">{{"glossary.blog.title" | translate}}</h2>
        <!-- Articles Cards -->
        <div class="articles-panel" [style]="(isHandset$ | async) ? 'margin-top: 3rem;' : ''">
            <div class="article-card fadePanelArticles fade-panel" *ngFor="let article of modelDefinition?.template?.articles">
                <app-article-card
                    [key]="article.key"
                    [title]="article.title"
                    [text]="article.text"
                    [link]="article.link"
                ></app-article-card>
            </div>
        </div>
    </div>
  
    <!----------------------- Bandeau démo ------------------------->
  <div style="margin-top: 10rem" class="page-demo-panel">
    <!-- Background -->
    <div class="page-demo-background">
      <div style="width: 50rem; height: 40rem; top: -61%; left: 76%" class="gradient-circle blue-gradient"></div>
      <div style="top: -8%; left: 80%; transform: rotate(140deg); opacity: 0.9" class="gradient-small-circle pink-gradient"></div>
      <div style="top: -13%;left: 82%;transform: rotate(140deg);filter: blur(5rem);" class="gradient-small-circle yellow-gradient"></div>
      <div style="width: 50rem; height: 40rem; top: 40%; left: 65%" class="gradient-circle blue-gradient"></div>
      <div style="top: 39%; left: 81%; opacity: 0.8" class="gradient-small-circle green-gradient"></div>
      <div style="width: 50rem; height: 50rem; top: 76%; left: 73%; opacity: 0.7" class="gradient-circle purple-gradient"></div>
    </div>

    <!-- title -->
    <div class="page-panel-title">
      <img src="assets/images/landings/page/hands/hand_demo.svg" alt="icon_hand_demo"/>
      <h2 class="dark-color">{{ "glossary.cta.title" | translate }}</h2>
      <h4 class="dark-color" appColoredText [textColor]="'#7285D8'" textKey="glossary.cta.description"></h4>
    </div>

    <!-- Bouton démo -->
    <moffi-ui-button
      (eventClick)="redirectTo('contact')"
      buttonType="dark"
      label="{{ 'glossary.cta.button' | translate }}"
    ></moffi-ui-button>
  </div>
</div>
